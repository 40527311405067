var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"limiting_wrapper"},[(_vm.show_account_details)?_c('div',[_c('v-toolbar',{staticClass:"pb-4 grey lighten-3 mb-4 toolbar",class:{
             'full-width': _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs,
             'medium-width':  _vm.$vuetify.breakpoint.md,
             'large-width':  _vm.$vuetify.breakpoint.lg,
             },attrs:{"text":""}},[_c('div',{staticClass:"d-flex bold pointer"},[_c('v-toolbar-title',[_vm._v(_vm._s(((_vm.getCurrentAccountInView.code) + ": " + (_vm.getCurrentAccountInView.title) + " Account")))])],1)]),_c('section',[_c('v-row',{staticClass:"d-flex mx-4 no-gutters bold"},[_c('v-col',{staticClass:"col-12"},[_vm._v(" "+_vm._s(("SELLER: " + (_vm.getCurrentAccountInView.user.code) + ":" + (_vm.getCurrentAccountInView.user.username)))+" ")]),_c('v-col',{staticClass:"col-12"},[_vm._v(" "+_vm._s(("PROFILE: " + (_vm.getCurrentAccountInView.profile_origin) + " ( " + (_vm.getCurrentAccountInView.profile_gender) + " )"))+" ")]),_c('v-col',{staticClass:"col-12"},[_vm._v(" "+_vm._s(("RATING: " + (_vm.getCurrentAccountInView.rating) + "%"))+" ")]),_c('v-col',{staticClass:"col-12"},[_vm._v(" "+_vm._s(("TOTAL ORDERS: " + (_vm.getCurrentAccountInView.total_orders)))+" ")]),_c('v-col',{staticClass:"col-12"},[_vm._v(" "+_vm._s(("PENDING ORDERS: " + (_vm.getCurrentAccountInView.pending_orders)))+" ")]),_c('v-col',{staticClass:"col-12"},[_vm._v(" "+_vm._s(("COST: " + (_vm.formatMoney(_vm.getCurrentAccountInView.cost)) + " ( "))+" "+_vm._s(_vm.getCurrentAccountInView.negotiable ? 'Negotiable )' : 'Non-negotiable )')+" ")]),_c('v-col',{staticClass:"col-12"},[_vm._v(" "+_vm._s("SCREENSHOTS")+" ")]),_vm._l((6),function(ima,i){return _c('v-col',{key:i,staticClass:"col-12 mb-2"},[_c('v-img',{attrs:{"src":'https://kazibin.nyc3.cdn.digitaloceanspaces.com/Accounts/IJ-4XT/mTjyNxeknREyoul5q3TwZha6THfjvBOj5zWJxb1W.png',"contain":""}})],1)}),_c('div',{staticClass:"d-flex justify-end call-button"},[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
             var on = ref.on;
             var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"white--text pointer"},'span',attrs,false),on),[_c('v-btn',{staticClass:"elevation- ml-2 red lighten-2 white--text",staticStyle:{"font-weight":"900"},attrs:{"small":"","loading":false}},[_vm._v(" Contact Seller ")])],1)]}}],null,false,3701297873)},[_c('v-list',[_c('v-list-item',{staticClass:"pointer",on:{"click":_vm.call}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-phone ")]),_vm._v(" Call ")],1),_c('v-list-item',{staticClass:"pointer",on:{"click":_vm.sms}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-message ")]),_vm._v(" SMS ")],1),_c('v-list-item',{staticClass:"pointer",on:{"click":_vm.sendEmail}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-mail ")]),_vm._v(" Email ")],1),_c('v-list-item',{staticClass:"pointer",on:{"click":_vm.goToWhatsApp}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-whatsapp ")]),_vm._v(" WhatsApp ")],1)],1)],1)],1)],2)],1)],1):_c('div',[_c('fetching-items',{attrs:{"message":"Fetching account"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }